import React, { useEffect } from 'react';

export default function KuulaProject(props) {

	useEffect(() => {
		// console.log('window', window)
	}, []);

	return (
		<div>
			<iframe class="ku-embed" 
				title="Lil Bitcoin Crypto Money Mansion"
				frameborder="0" allowfullscreen allow="xr-spatial-tracking; gyroscope; accelerometer" scrolling="no" 
				src="https://kuula.co/share/collection/7YFdZ?logo=-1&info=0&fs=0&vr=0&autorotate=0&thumbs=-1&margin=5&inst=0"></iframe>
    	</div>
	)
}
